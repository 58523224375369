.App {
  background-color: #130024ab;
  box-shadow: 0 0 15px #000;
}

@media only screen and (min-width: 576px) {
  .App {
    border-radius: 10px;
  }
}

.App-space-top {
  margin-top: 2rem;
}