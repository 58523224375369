.Clickable:hover .Arrow-icon {
    filter: drop-shadow(0 0 10px #ddb7d8aa);
}

.Clickable {
    cursor: pointer;
}

.Qa-card {
    margin-top: 0.25rem;
}

.Qa-title {
    line-height: 1.3;
}

.Arrow-icon {
    height: 0.8rem;
    margin-bottom: 0.4rem;
}

.Arrow-up {
    transform: rotate(180deg);
    transition: transform 0.2s linear;
}

.Arrow-down {
    transform: rotate(0deg);
    transition: transform 0.2s linear;
}