.Content-h1 {
  padding-top: 3rem;
  padding-bottom: 2rem;
  text-transform: uppercase;
}

.Content-h2 {
  padding-top: 2rem;
  padding-bottom: 1rem;
  text-transform: uppercase;
}

.Content-small-margin-bottom {
  margin-bottom: 0.3rem;
}

.Content-text {
  font-size: 1.3rem;
}

.Content-bold {
  font-weight: bold;
}

.Content-alert {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
}

/* for lg and bigger screens */

@media only screen and (min-width: 992px) {
  .Content-space-side {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

/* for screen bigger than xl */

@media only screen and (min-width: 1700px) {
  .Content-space-side {
    padding-left: 16rem;
    padding-right: 16rem;
  }
}

.Content-spacer {
  margin-top: 3rem;
}

.Content-icon {
  height: 3rem;
}

.Content-icon:hover {
  filter: drop-shadow(0 0 10px #f8bdf1d7);
}

.Content-full-width-image {
  max-width: 95%;
}

.Content-image-height-restricted {
  max-height: 20rem;
}

.Content-image-title {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

a {
  word-wrap: break-word;
}