.nft img {
    max-width: 100%;
    margin-bottom: 0.5rem;
}

.nft p {
    margin-bottom: 0.1rem;
}

.nft {
    margin-bottom: 2rem;
}