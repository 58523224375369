.MarketplaceButton {
  border-radius: 30px;
  margin: 3px;
}

.MarketplaceButton:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: -2px;
  margin-left: -2px;
}

.MarketplaceButton img {
  border-radius: 30px;
  width: 100%;
}
