body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(./img/bg.jpg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.center-text {
  text-align: center;
}

h1, h2, h3 {
  background: linear-gradient(#ffbcb4, #e42b94);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 1px;
}

h3 a:hover {
  text-decoration: none;
}

::selection {
  background: #ffbcb4;
  color: #e42b94;
}