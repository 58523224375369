// This file is used to override bootstrap css
// see: https://create-react-app.dev/docs/adding-bootstrap/#using-a-custom-theme
// see also: https://getbootstrap.com/docs/4.0/getting-started/theming/

// Override default variables before the import
$body-bg: #3d2a54;
$body-color: #e9dcc5;
$card-bg: #02002477;
$primary: #ca8ee6;

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

.card {
  border: 0;
}

.modal-header .close:hover {
  color: #b7c8dd;
  text-shadow: 0 1px 0 #000;
}

.modal-content {
  background-color: #1c1d25;
  box-shadow: 0 0 15px #000;
  border: 0;
}

.modal-header .close {
  color: #b7c8dd;
  text-shadow: 0 1px 0 #000;
}

.navbar-expand-md .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}

/* for large screens (not a bootstrap unit) bigger screens */

@media only screen and (min-width: 1700px) {
  .container {
    min-width: 1540px;
    padding-left: 0;
    padding-right: 0;
  }
}
