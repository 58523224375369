.Navigation {
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-size: 1.05rem;
  letter-spacing: 0.02rem;
  color: #ffbcb4;
}

.Navbar {
  background-color: #020024cc;
  box-shadow: 0 0 15px #020024;
}

.Nav-icon {
  height: 3.5rem;
  position: absolute;
  margin-top: -1.75rem;
}

/* for sm and bigger screens */

@media only screen and (min-width: 576px) {
  .Navbar {
    border-radius: 10px;
  }
  .Navigation {
    padding-top: 15px;
  }
}

/* for md and bigger screens */

@media only screen and (min-width: 768px) {
  .Nav-icon {
    height: 5.0rem;
    margin-top: -2.5rem;
  }
  .Nav-brand {
    margin-right: 6rem !important;
  }
}

.Link-icon:hover {
  filter: drop-shadow(0 0 10px #ddb7d8aa);
}

.Link-icon {
  height: 1.6rem;
  padding-right: 0.2rem;
  padding-left: 0.5rem;
}

.Nav-current-page, .Nav-other-page:hover {
  background: linear-gradient(#ffbcb4, #e42b94);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  text-shadow: 0 0 14px #ddb7d8aa;
}

.Nav-other-page {
  font-weight: bold;
  color: #b7c8dd;
  background: linear-gradient(#ce9993, #c2267e);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}