footer {
  padding-top: 2rem;
  padding-bottom: 3rem;
  text-align: center;
  color: #C2C2C2;
}

.Footer-logo {
  opacity: 0.8;
}

.Donate-icon {
  height: 2rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.Big-margin-top {
  margin-top: 2rem;
}

.Small-margin-top {
  margin-top: 1rem;
}

.No-margin-bottom {
  margin-bottom: 0rem;
}

.Donate-modal {
  border-color: #e19f27 !important;
  color: #6AA0CE;
}

.Donate-modal h3 {
  margin-bottom: 0;
}

.Qr-image {
  width: 100%;
}

.Wrap-address {
  white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap;          /* Chrome & Safari */
  white-space: -pre-wrap;                 /* Opera 4-6 */
  white-space: -o-pre-wrap;               /* Opera 7 */
  white-space: pre-wrap;                  /* CSS3 */
  word-wrap: break-word;                  /* Internet Explorer 5.5+ */
  word-break: break-all;
  white-space: normal;
}