.Explore-project-link {
    border: 0;
    font-weight: 600;
    color: #b7c8dd;
    background: linear-gradient(#ce9993, #c2267e);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.5rem;
}

.Explore-project-link-current, .Explore-project-link:hover {
    cursor: pointer;
    background: linear-gradient(#ffbcb4, #e42b94);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    text-shadow: 0 0 14px #ddb7d8aa;
    font-size: 1.5rem;
}